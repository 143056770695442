const getDefaultState = () => {
  return {
    activeTab: 0,

    initData: {},

    tabCustomer: {},

    tabProperti: {},

    tabAngsuran: {},

    tabApprovals: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {},

  mutations: {
    SET_ACTIVE_TAB (state, tabIndex) {
      document.body.scrollTop = 0 // safari
      document.documentElement.scrollTop = 0 // chrome, firefox, ie and opera
      state.activeTab = tabIndex
    },
    SET_INIT_DATA (state, payload) {
      state.initData = payload
    },
    SET_TAB_CUSTOMER (state, payload) {
      state.tabCustomer = payload
    },
    SET_TAB_PROPERTI (state, payload) {
      state.tabProperti = payload
    },
    SET_TAB_ANGSURAN (state, payload) {
      state.tabAngsuran = payload
    },
    SET_TAB_APPROVALS (state, payload) {
      state.tabApprovals = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
