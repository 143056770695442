<template>
  <div>
    <!--loading-->
    <vs-progress color="primary" :height="1" v-if="isLoadingInitData"/>

    <div class="vx-row mb-3">
      <div class="vx-col lg:w-9/12 w-full">
        <vx-card>
          <vs-tabs alignment="fixed" v-model="activeTab">
            <vs-tab label="Detail Kontrak" icon-pack="feather">
              <TabDetail :isActive="activeTab === 0" :initData="initData"/>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>

      <div class="vx-col lg:w-3/12 w-full mt-3 lg:mt-0">
        <!--approval progress-->
        <vx-card>
          <div class="flex items-center mb-base">
            <feather-icon icon="LayersIcon" svgClasses="h-5 w-5" class="mr-2"/>
            <h5 class="font-medium">Approval Progress</h5>
          </div>
          <ApprovalProgress
            ref="approvalProgress"
            type="BATAL-KONTRAK"
            :id-ref="$route.params.idBooking"
            :isShowSigningAction="true"
            @signingClicked="onClickSigningAction"/>
        </vx-card>
      </div>
    </div>

    <!--modals-->
    <KontrakBatalApprovalSigning :isActive.sync="modalSigning.active"
                                :idBooking="modalSigning.idBooking"
                                :status="modalSigning.status"
                                @success="onSigned"/>
  </div>
</template>

<script>
import moduleSkupApproval from '@/store/modules/approvals/skup-approval/skup-approval.store'
import BookingRepository from '@/repositories/marketing/booking-repository'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'SkupApprovalShow',
  components: {
    TabDetail: () => import('@/views/pages/approvals/kontrak-batal-approval/parts/TabDetail'),
    KontrakBatalApprovalSigning: () => import('@/views/pages/approvals/kontrak-batal-approval/KontrakBatalApprovalSigning'),
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoadingInitData: false,
      initData: {
        booking: {}
      },
      modalSigning: {
        active: false,
        idBooking: this.$route.params.idBooking,
        status: null
      }
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.approvals.skupApproval.activeTab
      },
      set (value) {
        this.$store.commit('approvals/skupApproval/SET_ACTIVE_TAB', value)
      }
    },
    ...mapState({
      dataTabDetail: state => state.approvals.skupApproval.tabDetail
    })
  },
  watch: {
    initData: {
      deep: true,
      handler (value) {
        this.commitInitDataToStore(value)
      }
    }
  },
  methods: {
    onSigned () {
      this.getInitData()
      this.$refs.approvalProgress.refresh()
    },

    getInitData () {
      this.getBookingData()
    },

    getBookingData () {
      this.isLoadingInitData = true

      const idBooking = this.$route.params.idBooking
      const params = { withUnitInformation: true }
      BookingRepository.show(idBooking, params)
        .then(response => {
          this.initData.booking = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    onClickSigningAction (status) {
      this.modalSigning.status = status
      this.modalSigning.active = true
    },

    commitInitDataToStore (value) {
      this.$store.commit('approvals/skupApproval/SET_INIT_DATA', _.cloneDeep(value))
    }
  },
  beforeCreate () {
    this.$store.registerModule(['approvals', 'skupApproval'], moduleSkupApproval)
  },
  beforeDestroy () {
    this.$store.commit('approvals/skupApproval/RESET_STATE')
    this.$store.unregisterModule(['approvals', 'skupApproval'])
  }
}
</script>
